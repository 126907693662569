<template>
  <div class="cart__list">
    <div
      v-for="item in order && getOrder"
      :key="item.id"
      class="cart__item mb-5"
    >
      <div
        v-if="order.order_items[0].sole_id"
        class="cart__item-container cart__item-container--top"
      >
        <div>
          <div class="item-container__title">
            {{ $t('PRODUCT_DESCRIPTION.table.image') }}
          </div>
          <div class="pa-4" style="max-height: 200px; height: 100%">
            <img
              style="width: 100%; height: 100%; object-fit: cover;"
              :src="baseUrl + item?.reyestr.photo"
              :alt="getImageName(item?.reyestr.photo)" :title="getImageName(item?.reyestr.photo)" 

            />
          </div>
        </div>
        <div>
          <div class="item-container__title">
            {{ $t('PRODUCT_DESCRIPTION.table.details') }}
          </div>
          <div class="pa-4 d-flex flex-column">
            <span style="font-weight: 700">{{
              item.reyestr.name[activeLang.language]
            }}</span>
            <span v-if="item.sole_id" style="font-weight: 700">
              Подошва: {{ item.sole.name }}
            </span>
          </div>
          <div class="px-4">
            <span style="font-weight: 700"
              >{{ $t('PRODUCT_DESCRIPTION.branding') }}:
            </span>
            <ul class="pa-0 ma-0">
              <li
                v-for="branding in getSelectedBrands.selected_brands.filter(
                  (el) => el.sole_id === item.sole_id
                )"
                :key="branding.id"
              >
                <span v-if="branding">{{ branding.id }}. </span>
                <span v-if="branding"
                  >{{ branding.name }} = {{ branding.price }}$</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="item-container__inner">
          <div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.price') }}
            </div>
            <div
              style="border-bottom: 1px solid #f5f5f5"
              class="position-relative item-container__content"
              v-for="(size, index) in getOrderDetails?.sizes?.filter(
                (size) => size?.sole_id === item.sole_id
              )"
              :key="index"
            >
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700">
                  {{ (size?.price && size?.price) || 'not defined' }}$
                </span>
              </div>
            </div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.pre_count') }}
            </div>
            <div class="position-relative item-container__content"> </div>
          </div>
          <div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.color') }}
            </div>
            <div
              style="border-bottom: 1px solid #f5f5f5"
              class="position-relative item-container__content"
              v-for="(color, index) in getOrderDetails?.colors.filter(
                (el) => el.sole_id === item.sole_id
              )"
              :key="index"
            >
              <div class="d-flex align-center justify-center">
                <!-- :style="{
                          backgroundColor: item.colors.find(
                            (color) => color.id === size.colorId
                          ).css_code,
                        }" -->
                <div
                  :style="{
                    background: color?.color,
                  }"
                  class="item-container__content-color"
                >
                </div>
              </div>
            </div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.color') }}
            </div>
            <div class="position-relative item-container__content">
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700; font-size: 20px">
                  {{
                    getOrderDetails.colors.filter(
                      (el) => el.sole_id === item.sole_id
                    ).length
                  }}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="item-container__title">{{
              $t('PRODUCT_DESCRIPTION.table.size')
            }}</div>
            <div
              style="border-bottom: 1px solid #f5f5f5"
              class="position-relative item-container__content"
              v-for="(size, index) in getOrderDetails.sizes.filter(
                (el) => el.sole_id === item.sole_id
              )"
              :key="index"
            >
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700; font-size: 20px">
                  {{ size.size }}
                </span>
              </div>
            </div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.size') }}
            </div>
            <div class="position-relative item-container__content">
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700; font-size: 20px">
                  {{
                    getOrderDetails.sizes.filter(
                      (el) => el.sole_id === item.sole_id
                    ).length
                  }}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.count') }}
            </div>
            <div
              style="border-bottom: 1px solid #f5f5f5"
              class="position-relative item-container__content"
              v-for="(count, index) in getOrderDetails.sizes.filter(
                (el) => el.sole_id === item.sole_id
              )"
              :key="index"
            >
              <div class="d-flex align-center justify-center pa-2">
                <span
                  class="text-center px-3 py-1 mx-1"
                  style="
                                    border-radius: 5px;
                                    font-weight: 700;
                                    font-size: 20px;
                                  "
                >
                  {{ count.count }}
                </span>
              </div>
            </div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.count') }}
            </div>
            <div class="position-relative item-container__content">
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700; font-size: 20px">
                  {{ order && order.count }}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.total') }}
            </div>
            <div
              v-for="(sum, index) in getOrderDetails.sizes.filter(
                (el) => el.sole_id === item.sole_id
              )"
              :key="index"
              class="position-relative item-container__content"
            >
              <div
                style="border-bottom: 1px solid #f5f5f5"
                class="d-flex align-center justify-center"
              >
                <span style="font-weight: 700">
                  $
                  {{ sum.sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </span>
              </div>
            </div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.total') }}
            </div>
            <div class="position-relative item-container__content">
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700"
                  >$
                  {{
                    order &&
                      order.sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="cart__item-container cart__item-container--top">
        <div>
          <div class="item-container__title">
            {{ $t('PRODUCT_DESCRIPTION.table.image') }}
          </div>
          <div class="pa-4" style="max-height: 200px; height: 100%">
            <img
              style="width: 100%; height: 100%"
              :src="baseUrl + item?.reyestr.photo"
              :alt="getImageName(item?.reyestr.photo)" :title="getImageName(item?.reyestr.photo)" 
            />
          </div>
        </div>
        <div>
          <div class="item-container__title">
            {{ $t('PRODUCT_DESCRIPTION.table.details') }}
          </div>
          <div class="pa-4 d-flex flex-column">
            <span style="font-weight: 700">{{
              item.reyestr.name[activeLang.language]
            }}</span>
          </div>
          <div class="px-4">
            <span style="font-weight: 700"
              >{{ $t('PRODUCT_DESCRIPTION.branding') }}:
            </span>
            <ul class="pa-0 ma-0">
              <li
                v-for="branding in getSelectedBrands.selected_brands"
                :key="branding.id"
              >
                <span v-if="branding">{{ branding.id }}. </span>
                <span v-if="branding"
                  >{{ branding.name }} = {{ branding.price }}$</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="item-container__inner">
          <div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.price') }}
            </div>
            <div
              style="border-bottom: 1px solid #f5f5f5"
              class="position-relative item-container__content"
              v-for="(size, index) in getOrderDetails?.sizes"
              :key="index"
            >
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700">
                  {{ (size?.price && size?.price) || 'not defined' }}$
                </span>
              </div>
            </div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.pre_count') }}
            </div>
            <div class="position-relative item-container__content"> </div>
          </div>
          <div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.color') }}
            </div>
            <div
              style="border-bottom: 1px solid #f5f5f5"
              class="position-relative item-container__content"
              v-for="(color, index) in getOrderDetails?.colors"
              :key="index"
            >
              <div class="d-flex align-center justify-center">
                <!-- :style="{
                          backgroundColor: item.colors.find(
                            (color) => color.id === size.colorId
                          ).css_code,
                        }" -->
                <div
                  :style="{
                    background: color?.color,
                  }"
                  class="item-container__content-color align-center"
                >
                  <!-- {{ color?.color }} -->
                </div>
              </div>
            </div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.color') }}
            </div>
            <div class="position-relative item-container__content">
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700; font-size: 20px">
                  {{ getOrderDetails.colors.length }}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="item-container__title">{{
              $t('PRODUCT_DESCRIPTION.table.size')
            }}</div>
            <div
              style="border-bottom: 1px solid #f5f5f5"
              class="position-relative item-container__content"
              v-for="(size, index) in getOrderDetails.sizes"
              :key="index"
            >
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700; font-size: 20px">
                  {{ size.size }}
                </span>
              </div>
            </div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.size') }}
            </div>
            <div class="position-relative item-container__content">
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700; font-size: 20px">
                  {{ getOrderDetails.sizes.length }}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.count') }}
            </div>
            <div
              style="border-bottom: 1px solid #f5f5f5"
              class="position-relative item-container__content"
              v-for="(count, index) in getOrderDetails.sizes"
              :key="index"
            >
              <div class="d-flex align-center justify-center pa-2">
                <span
                  class="text-center px-3 py-1 mx-1"
                  style="
                                    border-radius: 5px;
                                    font-weight: 700;
                                    font-size: 20px;
                                  "
                >
                  {{ count.count }}
                </span>
              </div>
            </div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.count') }}
            </div>
            <div class="position-relative item-container__content">
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700; font-size: 20px">
                  {{ order && order.count }}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.total') }}
            </div>
            <div
              v-for="(sum, index) in getOrderDetails.sizes"
              :key="index"
              class="position-relative item-container__content"
            >
              <div
                style="border-bottom: 1px solid #f5f5f5"
                class="d-flex align-center justify-center"
              >
                <span style="font-weight: 700"> $ {{ sum.sum }} </span>
              </div>
            </div>
            <div class="item-container__title">
              {{ $t('PRODUCT_DESCRIPTION.table.total') }}
            </div>
            <div class="position-relative item-container__content">
              <div class="d-flex align-center justify-center">
                <span style="font-weight: 700"
                  >$
                  {{ order && order.sum }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart__item-container cart__item-container--bottom">
        <div
          class="item-container__castomization-btn d-flex justify-center align-center"
        >
          <div style="width: 70%">
            <BaseButton
              title="Брендирование"
              @click="openCustomizationDialog(getSelectedBrands, item.sole_id)"
            />
          </div>
        </div>
        <div class="item-container__castomization-logo pa-4">
          <div class="d-flex justify-center align-center">
            <span style="font-weight: 700">
              {{ $t('PRODUCT_DESCRIPTION.notes') }}
            </span>
          </div>
          <div class="d-flex justify-center align-center px-6">
            <div
              style="
                                width: 100%;
                                height: 100%;
                                border: 1px dashed black;
                              "
              class="d-flex align-center justify-center"
            >
              {{ $t('PRODUCT_DESCRIPTION.logo_place') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from '../../layout/BaseButton.vue'
import { getImageName } from '@/utils/utils'

export default {
  name: 'OrderTableInfo',
  props: {
    order: {
      type: Object,
    },
    activeLang: {
      type: Object,
    },
    openCustomizationDialog: {
      type: Function,
    },
  },
  components: {
    BaseButton,
  },
  data() {
    return {
      baseUrl: '',
    }
  },
  computed: {
    total(item) {
      let sum = 0
      sum += item.price * item.count
      return sum
    },
    getSelectedBrands() {
      const selectedBrands = []
      const mergedObjects = {}

      this.order.order_items.forEach((obj) => {
        if (obj.sole_id) {
          const id = obj.sole_id
          if (!mergedObjects[id]) {
            mergedObjects[id] = {
              selected_brands: [],
            }
          }
          const existingItem = mergedObjects[id].selected_brands.find(
            (el) => el.id === obj.selected_sole_brand.id
          )
          if (!existingItem) {
            mergedObjects[id].selected_brands.push({
              ...obj.selected_sole_brand,
              sole_id: obj.sole_id,
            })
          }
        } else {
          const id = obj.reyestr_id
          if (!mergedObjects[id]) {
            mergedObjects[id] = {
              selected_brands: [],
            }
          }
          const existingItem = mergedObjects[id].selected_brands.find(
            (el) => el.id === obj.brand.id
          )
          if (!existingItem) {
            mergedObjects[id].selected_brands.push(obj.brand)
          }
        }
      })

      for (const id in mergedObjects) {
        selectedBrands.push(...mergedObjects[id].selected_brands)
      }
      return {
        selected_brands: selectedBrands,
      }
    },
    getOrder() {
      if (this.order.order_items[0].sole_id) {
        const filteredArray = this.order.order_items.filter(
          (item, index, array) => {
            // Ищем первое вхождение элемента с таким же sole_id в массиве
            const firstIndex = array.findIndex(
              (el) =>
                el.sole_id === item.sole_id && el.reyestr_id === item.reyestr_id
            )
            // Если индекс текущего элемента равен первому индексу с таким же sole_id, оставляем его
            return index === firstIndex
          }
        )
        return filteredArray
      } else {
        const filteredArray = this.order.order_items.filter(
          (item, index, array) => {
            // Ищем первое вхождение элемента с таким же sole_id в массиве
            const firstIndex = array.findIndex(
              (el) => el.reyestr_id === item.reyestr_id
            )
            // Если индекс текущего элемента равен первому индексу с таким же sole_id, оставляем его
            return index === firstIndex
          }
        )
        return filteredArray
      }
    },
    getOrderDetails() {
      let colors = []
      let sizes = []
      if (this.order.order_items[0].sole_id) {
        this.order.order_items.forEach((item) => {
          sizes.push({
            size: item?.size,
            sole_id: item?.sole_id,
            reyestr_id: item?.reyestr_id,
            price: item?.price,
            count: item?.count,
            sum: +item?.price * item?.count,
          })
        })
        this.order.order_items.forEach((item) => {
          let color = {
            sole_id: item?.sole_id,
            reyestr_id: item?.reyestr_id,
          }
          item.reyestr.colors
            .filter((s) => s.name === item.color)
            .forEach((el) => {
              color.color = el?.css_code
              color.photo_path = el?.photo_path
            })
          colors.push(color)
        })
      } else {
        this.order.order_items.forEach((item) => {
          sizes.push({
            size: item?.size,
            reyestr_id: item?.reyestr_id,
            price: item?.price,
            count: item?.count,
            sum: +item?.price * item?.count,
          })
        })
        this.order.order_items.forEach((item) => {
          item?.reyestr
            ? item?.reyestr?.colors
                ?.filter((s) => s.name === item.color)
                .forEach((el) => {
                  colors.push({
                    reyestr_id: item?.reyestr_id,
                    color: el?.css_code,
                    photo_path: el?.photo_path,
                  })
                })
            : colors.push({
                reyestr_id: item?.reyestr_id,
                color: item?.color,
              })
        })
      }
      return {
        sizes,
        colors,
      }
    },
  },
  methods: {
    getImageName,
  },
  mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
  },
}
</script>
<style lang="scss">
$white: #ffffff;
$grey: #b5b5b5;
$gr: #f5f5f5;
$bl: #423f3d;
$black: #31343c;
$gr_c: #fbfbfb;
$grtx: #a4a4a4;
$green: #cfebcc;
$gr_col: #818181;
$green_c: #a3c2a0;
$bl_c: #90ace2;

@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

.cart__list {
  overflow-x: auto;
}

.container_order {
  background-color: #fff;
}
.cart__item {
  display: grid;
}
.cart__item-container {
  display: grid;
  border: 1px solid $gr;
  &--top {
    grid-template-columns: 200px 200px 1fr;
    & > div:not(:last-child) {
      border-right: 1px solid $gr;
    }
  }
  &--bottom {
    grid-template-columns: 400px 1fr;
    border-top: none;
  }
}
.item-container {
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    & > div:not(:last-child) {
      border-right: 1px solid $gr;
    }
  }
  &__title {
    border-bottom: 1px solid $gr;
    background-color: $gr;
    text-transform: uppercase;
    @include ftz(16px, 400, $bl);
    text-align: center;
  }
  &__content {
    width: 106px;
    padding-top: 60px;
    & > div {
      position: absolute;
      inset: 0;
    }
    &-color {
      width: 50%;
      height: 50%;
    }
  }
  &__castomization-btn {
    height: 100%;
    border-right: 1px solid $gr;
  }
  &__castomization-logo {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }
}
.content {
  &__button {
    color: $grey;
    flex-grow: 1;
    cursor: pointer;
    &--increase {
      background-color: $green;
    }
    &--decrease {
      background-color: $gr;
    }
  }
}
.btn_item {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 9px 26px;
  font-size: 16px;
  color: #666666;
  .btn_product_sum {
    font-size: 24px;
    font-family: 'Roboto Condensed';
  }
  .btn_product_text {
    font-weight: 500;
  }
}
</style>
