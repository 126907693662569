<template>
  <button
    type="button"
    class="button"
    :class="{ 'button-disabled': disabled }"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: 'Base Button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin ftz($fsize, $fweight, $color) {
  font-size: $fsize;
  font-weight: $fweight;
  color: $color;
}
.button {
  width: 100%;
  height: fit-content;
  padding: 10px 0;
  @include ftz(12px, 700, #666666);
  line-height: 18px;
  border-radius: 2px;
  text-transform: uppercase;
  font-family: 'Alegreya Sans';
  background-color: #cfebcc;
  letter-spacing: 0.13em;
  &-disabled {
    background-color: #eee;
    color: #939393;
    cursor: default;
  }
}
</style>
